import React from 'react'
import { NavLink } from 'react-router-dom';
import {FaLinkedinIn} from 'react-icons/fa';
import {AiFillGithub, AiOutlineTwitter, AiFillInstagram} from 'react-icons/ai';

const Footer = ({mode}) => {
    
  return (
    <div className={`${mode ? ' bg-slate-800' :' bg-slate-800'} text-white static bottom-0 pb-24 sm:z-50 md:pb-5`}>
        <div className='hidden py-5 md:flex gap-20 justify-center'>
            <div>
                <p className='sm:text-2xl text-lg font-bold'>Jatin Pandey</p>
                <p className='text-md'>Full Stack developer</p>
            </div>
            <div className='flex gap-5 text-lg font-normal'>
                <NavLink to='/'>Home</NavLink>
                <NavLink to='/about'>About</NavLink>
                <NavLink to='/projects'>Projects</NavLink>
                <NavLink to='/resume'>Resume</NavLink>
            </div>
            <div className='flex px-10 gap-3'>
                    <a href='https://www.linkedin.com/in/jatinpandey3250/' target='_blank'> <FaLinkedinIn className='text-xl hover:scale-125' /> </a>
                    <a href='https://github.com/jatin-pandey01' target='_blank'> <AiFillGithub className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
                    <a href='https://twitter.com/Jatin__Pandey' target='_blank'> <AiOutlineTwitter className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
                    <a href='https://www.instagram.com/cout.jatinpandey' target='_blank'> <AiFillInstagram className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
            </div>
        </div>
        <div className='flex flex-col py-3 md:hidden'>
            <div className='flex items-center justify-around'>
                <div>
                    <p className='sm:text-2xl text-lg font-bold'>Jatin Pandey</p>
                    <p className='text-sm'>Full Stack developer</p>
                </div>
                <div className='flex flex-col sm:flex-row gap-3 text-lg font-normal'>
                    <NavLink to='/'>Home</NavLink>
                    <NavLink to='/about'>About</NavLink>
                    <NavLink to='/projects'>Projects</NavLink>
                    <NavLink to='/resume'>Resume</NavLink>
                </div>
            </div>
            <div className='flex justify-center py-5 gap-3'>
                    <a href='https://www.linkedin.com/in/jatinpandey3250/' target='_blank'> <FaLinkedinIn className='text-xl hover:scale-125' /> </a>
                    <a href='https://github.com/jatin-pandey01' target='_blank'> <AiFillGithub className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
                    <a href='https://twitter.com/Jatin__Pandey' target='_blank'> <AiOutlineTwitter className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
                    <a href='https://www.instagram.com/cout.jatinpandey' target='_blank'> <AiFillInstagram className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
            </div>
        </div>
        <div className='text-lg font-medium text-center'>
            &copy;Jatin Pandey. All rights reserved
        </div>
    </div>
  )
}

export default Footer;