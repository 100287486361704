import React, { useState } from 'react'
import Navbar from './components/Navbar'
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Project from './pages/Project';
import Resume from './pages/Resume';
import Particle from './components/Particle';

function App() {
  const [mode,setMode] = useState(false);
  return (
    <div className={`${mode ? 'text-black light-gradient' :'text-white black-gradient'} min-w-full min-h-screen`}>
      <Particle mode={mode}/> 
      <Routes>
        <Route path='/' index element={<Home mode = {mode} setMode={setMode} />}/>
        <Route path='/about' element={<About mode = {mode} setMode={setMode} />} />
        <Route path='/projects' element={<Project mode = {mode} setMode={setMode} />} />
        <Route path='/resume' element={<Resume mode = {mode} setMode={setMode} />} /> 
        <Route path='*' element={<Home mode = {mode} setMode={setMode} />} />
      </Routes>
    </div>
  );
}

export default App;
