import React, { useEffect } from 'react';
import about from '../assets/About.png';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaGraduationCap } from "react-icons/fa6";
import Footer from '../components/Footer';
import { skills, tools } from '../constant/Data';
import Navbar from '../components/Navbar';

const About = ({mode,setMode}) => {
  useEffect(()=>{
    document.title = "ABOUT | Jatin Portfolio"
},[]);
  return (
    <div>
      <Navbar mode={mode} setMode={setMode} isBlack={false} />
      <div className='pt-5 md:pt-28'>
      <p className='text-3xl text-center font-black text-purple-700'> About Me </p>
      <p className='text-center font-bold'> My introduction </p>
      <div className='flex justify-center items-center flex-wrap-reverse'>
        <div className='text-xl font-bold max-w-xl p-5 mt-5 white-glassmorphism'>
          <p className=''>
            Hey there,👋 I am <span className='text-purple-700 font-black'> Jatin Pandey </span> from <span className='text-purple-700 font-black'> Mumbai, India.</span>
          </p>
          <p> 
            I am a Third Year student pursuing B.Tech in Computer Science and Engineering ( Internet of Things, Cyber Security and Blockchain Technology ) from Dwarkadas J. Sanghvi College of Engineering, Mumbai.
          </p>
          <p>
            I am full stack web and Flutter developer . I love solving problems and learning new things. I have solved 420+ problems on leetcode and 3⭐ on codechef.
          </p>
          <p>Here are my ratings on different coding platform :</p>
          <ol className='list-disc pl-5'>
            <li > <a href='https://leetcode.com/jatinpandey01/' target='_blank'>Leetcode : 1643</a> </li>
            <li > <a href='https://www.codechef.com/users/jatinpandey01' target='_blank'>Codechef : 1636</a> </li>
            <li > <a href='https://codeforces.com/profile/Jatinpandey01' target='_blank'>Codeforces : 995</a> </li>
          </ol>
          
        </div>
        <div>
          <img src={about} width={400} />
        </div>
      </div>
      <div >
        <p className='text-purple-700 sm:text-center font-bold text-2xl pt-5 mx-5'>Education</p>
        <VerticalTimeline lineColor='#2196F3' className={`}`} >
            
          <VerticalTimelineElement className='vertical-timeline-element--education' contentStyle={{ background:'transparent', color: `${mode ? 'text-black' : 'text-white'}` }} contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}  date="2021 - 2025" iconStyle={{ background: '#2196F3',  color: `${mode ? 'text-black' : 'text-white'}`  }} icon={<FaGraduationCap className='' />}>
            <h3 className="text-lg font-bold">B.Tech in Computer Science and Engineering(ICB)</h3>
            <h4 className="vertical-timeline-element-subtitle">Dwarkadas J. Sanghvi College of Engineering</h4>
          </VerticalTimelineElement>
              
          <VerticalTimelineElement className='vertical-timeline-element--education' contentStyle={{ background: 'transparent',  color: `${mode ? 'text-black' : 'text-white'}` }} contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}  date="2019 - 2021" iconStyle={{ background: '#2196F3',  color: `${mode ? 'text-black' : 'text-white'}`  }} icon={<FaGraduationCap />}>
            <h3 className="text-lg font-bold">Higher Secondary</h3>
            <h4 className="vertical-timeline-element-subtitle">Anudatt Vidyalaya & Jr. College</h4>
          </VerticalTimelineElement>
              
          <VerticalTimelineElement className='vertical-timeline-element--education' contentStyle={{ background: 'transparent',  color: `${mode ? 'text-black' : 'text-white'}` }} contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}  date="2009 - 2019" iconStyle={{ background: '#2196F3', color: `${mode ? 'text-black' : 'text-white'}`  }} icon={<FaGraduationCap />}>
            <h3 className="text-lg font-bold">Secondary School</h3>
            <h4 className="vertical-timeline-element-subtitle">Swami Vivekanand High School</h4>
          </VerticalTimelineElement>
      
        </VerticalTimeline>
      </div>
      <div className='flex flex-col items-center pt-10 mx-5'>
          <p className='text-purple-700 sm:text-center font-bold text-2xl '>Skills</p>
          <div className='flex flex-wrap gap-5 justify-center max-w-6xl pt-5'>
            {
              skills.map((s)=>{
                return <div key={s.id} className=' flex flex-col gap-2 justify-center items-center px-10 py-5 w-[200px] border-2 border-blue-500 rounded-lg hover:scale-105 duration-300 hover:shadow-blue-600 shadow-lg'>
                  <p className='text-5xl'>{s.src}</p>
                  <p className='text-xl'> {s.name} </p>
                </div>
              })
            }
          </div>
        </div>
        <div className='flex flex-col items-center py-10 mx-5'>
          <p className='text-purple-700 sm:text-center font-bold text-2xl '>Tools</p>
          <div className='flex flex-wrap gap-5 justify-center max-w-6xl pt-5'>
            {
              tools.map((s,index)=>{
                return <div key={index} className=' flex flex-col gap-2 justify-center items-center px-10 py-5 w-[200px] border-2 border-blue-500 rounded-lg hover:scale-105 duration-300 hover:shadow-blue-600 shadow-lg'>
                  <p className='text-5xl'>{s.src}</p>
                  <p className='text-xl'> {s.name} </p>
                </div>
              })
            }
          </div>
        </div>
      <Footer/>
      </div>
    </div>
  )
}

export default About;