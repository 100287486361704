import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import {MdOutlineLightMode, MdOutlineDarkMode} from 'react-icons/md';
import {HiViewGrid} from 'react-icons/hi';
import {RxCross1} from 'react-icons/rx';
import {FiHome} from 'react-icons/fi';
import {BsFillPersonFill} from 'react-icons/bs';
import {CgFileDocument} from 'react-icons/cg';
import {ImImages} from 'react-icons/im';

const Navbar = ({mode,setMode,isBlack}) => {
  const [showOptions,setShowOptions] = useState(false);
  return (
    <div>
      <div className={`hidden fixed top-0 z-[111111111] w-full blur-addition ${mode ?'text-black ':'text-white'} ${isBlack && !mode ? 'convert' :''} md:flex py-7 text-xl justify-around`}>
        <Link to='/' className='hover:text-blue-500 text-xl xxs:text-2xl'> Jatin</Link>
        <div className='flex gap-7 lg:gap-10 items-center relative'>
          <NavLink to='/' className='flex items-center gap-1 nav-link' > <FiHome/> Home</NavLink>
          <NavLink to='/about' className='flex items-center gap-1 nav-link' > <BsFillPersonFill/> About</NavLink>
          <NavLink to='/projects' className='flex items-center gap-1 nav-link' ><ImImages/> Projects</NavLink>
          <NavLink to='/resume' className='flex items-center gap-1 nav-link' ><CgFileDocument/> Resume</NavLink>
          {
            !mode ? <MdOutlineLightMode className='cursor-pointer text-2xl hover:text-blue-600' onClick={()=>setMode(!mode)} /> :
              <MdOutlineDarkMode className='cursor-pointer text-2xl hover:text-blue-600' onClick={()=>setMode(!mode)} />
          }
        </div>
      </div>
      {
        !showOptions ? 
          <div className='flex md:hidden justify-center'>
            <div className={`flex z-50 rounded-2xl px-5 duration-300 ease-linear items-center fixed w-9/12 bottom-2 ${mode ?'text-black bg-gray-300':'text-white bg-slate-900'} py-5 text-xl justify-between`}>
              <Link to='/' className='hover:text-blue-500'> Jatin </Link>
              <div className='flex gap-4 items-center'>
                {
                  !mode ? <MdOutlineLightMode className='cursor-pointer text-2xl hover:text-blue-600' onClick={async()=>setMode(!mode) } /> :
                    <MdOutlineDarkMode className='cursor-pointer text-2xl hover:text-blue-600' onClick={()=>setMode(!mode)} />
                }
                <HiViewGrid onClick={()=>setShowOptions(true)} className='cursor-pointer text-2xl hover:text-blue-600' />
              </div>
            </div>
          </div> : 
          <div className='flex justify-center md:hidden '>
            <div className={`px-5 visible rounded-3xl z-50 h-fit animate-up duration-500 ease-linear shadow-2xl fixed bottom-2 w-11/12 ${mode ?'text-black bg-gray-300':'text-white bg-slate-900'} py-5 text-xl`}>
              <div className='grid grid-cols-2 gap-5'>
                <NavLink to='/' className='hover:text-blue-500 flex flex-col items-center gap-1 xs:flex-row justify-center' onClick={()=>setShowOptions(false)}><FiHome/> Home</NavLink>
                <NavLink to='/about' className='hover:text-blue-500 flex flex-col items-center gap-1 xs:flex-row justify-center' onClick={()=>setShowOptions(false)}><BsFillPersonFill/> About</NavLink>
                <NavLink to='projects' className='hover:text-blue-500 flex flex-col items-center gap-1 xs:flex-row justify-center' onClick={()=>setShowOptions(false)}><ImImages/> Projects</NavLink>
                <NavLink to='/resume' className='hover:text-blue-500 flex flex-col items-center gap-1 xs:flex-row justify-center' onClick={()=>setShowOptions(false)}><CgFileDocument/>Resume</NavLink>
              </div>
              <div className='flex w-full pt-5 relative justify-end'>
                <RxCross1 onClick={()=> setShowOptions(false)} className='curson-pointer'/>
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default Navbar;