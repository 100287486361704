import React, { useEffect, useState } from 'react';
import resume from '../assets/JatinPandey_FullStack.pdf';
import {HiDownload} from 'react-icons/hi';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {Document,Page, pdfjs } from 'react-pdf';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Resume = ({mode,setMode}) => {
  const [width, setWidth] = useState(1200)

  useEffect(() => {
    setWidth(window.innerWidth);
    document.title = "RESUME | Jatin Portfolio";
  }, []);
  return (
    <div>
      <Navbar mode={mode} setMode={setMode} isBlack={true} />
      <div className='flex flex-col justify-between h-full'>
      <div className={`md:pt-40 pt-10 flex gap-10 flex-col items-center w-full h-full justify-center ${mode ? 'text-black light-gradient' :'text-white black-gradient'}`}>
        <div>
          <Document file={resume} className='d-flex justify-content-center'>
            <Page pageNumber={1} scale={width >= 1020 ? 1.7 : (width >= 840 ? 1.4 : (width >= 725 ? 1.2 : (width >= 600 ? 1 : (width >= 480 ? 0.8: (width >= 360 ? 0.6 : (width >= 300 ? 0.5 : 0.4) ) ))))} />
          </Document>
        </div>
        <a href={resume} target='_blank' className='flex items-center mb-10 gap-2 pt-3 text-white bg-[rgb(110,84,224)] w-fit px-3 text-xl rounded-md py-2 hover:bg-purple-800'>Download <HiDownload/> </a>
      </div>
      </div>
      <Footer mode={mode}/>
    </div>
  )
}

export default Resume;