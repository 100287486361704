import React, { useEffect, useState } from 'react';
import {AiOutlineSend} from 'react-icons/ai';
import emailjs from '@emailjs/browser';

const Form = () => {
  const [formData,setFormData] = useState({name:'', email:'', subject:'', message:''});
  const [color,setColor] = useState({name:null, email:null, subject:null, message:null});
  const [message,setMessage] = useState(false);
  const [display,setDisplay] = useState(false);

  const changeHandler = (e) => {
    setFormData(prevData=>{
      return {...prevData, [e.target.name]:e.target.value};
    });
    
  }

  useEffect(()=>{
    // console.log(color);
    if(formData.name.length > 0){
      setColor(prevData=>{
        return {...prevData, "name":true};
      })
    }
    else{
      setColor(prevData=>{
        return {...prevData, "name":false};
      })
    }

    if(formData.email.length > 0){
      setColor(prevData=>{
        return {...prevData, "email":true};
      })
    }
    else{
      setColor(prevData=>{
        return {...prevData, "email":false};
      })
    }

    if(formData.subject.length > 0){
      setColor(prevData=>{
        return {...prevData, "subject":true};
      })
    }
    else{
      setColor(prevData=>{
        return {...prevData, "subject":false};
      })
    }

    if(formData.message.length > 0){
      setColor(prevData=>{
        return {...prevData, "message":true};
      })
    }
    else{
      setColor(prevData=>{
        return {...prevData, "message":false};
      })
    }

  },[formData]);

  const sendMail = (e)=>{
    e.preventDefault();
    console.log("Hello");

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      to_name: 'Jatin Pandey',
      subject: formData.subject,
      message: formData.message
    };
  
    emailjs.send("service_b7m3w1n","template_roi63gf",templateParams,"LALuD_Y-2-NNYxQek")
    .then((res)=>{
      console.log("Email Sent successfully : ", res);
      setMessage(true);
      setDisplay(true);
      setTimeout(() => {
        setFormData({name:'', email:'', subject:'', message:''});
        setDisplay(false);
      }, 3000);
    })
    .catch((e)=>{
      setMessage(false);
      setDisplay(true);
      setTimeout(() => {
        setFormData({name:'', email:'', subject:'', message:''});
        setDisplay(false);
      }, 3000);
    });
  }

  return (
    <div className='flex flex-col gap-6 items-center mb-20 sm:mb-40' >
        <p className='text-2xl font-bold'>CONTACT FORM</p>
        <form className='flex flex-col mx-5 gap-5'>
            
            <div className='flex flex-col sm:flex-row gap-5'>
              <div className={`white-glassmorphism border-2 sm:w-6/12 border-violet-600 focus-within:${color.name ? 'border-green-600':'border-red-500'} px-3 py-2`}>
                <p className='text-xl font-medium'>Name</p>
                <input type='text' className='outline-none bg-transparent w-full' value={formData.name} name='name' onChange={changeHandler} required/>
              </div>
              <div className={`white-glassmorphism border-2 sm:w-6/12 border-violet-600 focus-within:${color.email ? 'border-green-600':'border-red-500'} px-3 py-2`}>
                <p className='font-medium text-xl'>Email</p>
                <input type='email' className='outline-none bg-transparent w-full' value={formData.email} name='email' onChange={changeHandler} required/>
              </div>
            </div>
            
            <div className={`white-glassmorphism border-2 border-violet-600 focus-within:${color.subject ? 'border-green-600':'border-red-600'} px-3 py-2`}>
              <p className='text-xl font-medium'>Subject</p>
              <input type='text' className='outline-none bg-transparent w-full' value={formData.subject} name='subject' onChange={changeHandler} required/>
            </div>
            
            <div className={`white-glassmorphism border-2 border-violet-600 focus-within:${color.message ?'border-green-600':'border-red-600'} px-3 py-2`}>
              <p className='text-xl font-medium'> Message </p>
              <textarea rows='5' cols='50' className='outline-none bg-transparent w-full' value={formData.message} name='message' onChange={changeHandler} required/>
            </div>

            <p className={`${display ? 'flex' : 'hidden'} mx-auto`}> {message ? <span  className='text-green-500'>Email Sent Successfully</span> : 
            <span className='text-red-500'>Something went wrong, please try again later</span> }</p>
            <button type='submit' className='flex items-center gap-2 mx-auto bg-violet-700 px-3 py-2 text-xl font-bold rounded-lg hover:bg-opacity-70' onClick={sendMail}> Send Message <AiOutlineSend/> </button>
        </form>
    </div>
  )
}

export default Form