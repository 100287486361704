import krypto from '../assets/projects/Krypto-wallet.jpg';
import jpMedia from '../assets/projects/JPMedia.jpg';
import ecomzy from '../assets/projects/Ecomzy.jpg';
import rickAndMorty from '../assets/projects/Rick-and-Morty.jpg';
import courses from '../assets/projects/Courses.jpg';
import newsApplication from '../assets/projects/News-application.jpg';
import { SiCplusplusbuilder, SiPython, SiJavascript, SiHtml5, SiCss3, SiReact, SiExpress, SiNodedotjs, SiMysql, SiMongodb, SiGit, SiSolidity, SiFigma, SiGithub,SiPostman, SiVercel, SiFirebase  } from "react-icons/si";
import { FaJava } from "react-icons/fa6";
import { TbBrandNextjs, TbBrandVscode  } from "react-icons/tb";

export const Data = [
    {   
        id:1, 
        name:"Krypto Wallet", 
        src:<img src={krypto} alt='Krypto Wallet' loading='lazy' width={400} />, 
        live:'https://krypto-wallet.vercel.app/', 
        git:'https://github.com/jatin-pandey01/Krypto-Wallet',
        info:"Krypto Wallet, a crossroads of modern technology, seamlessly sends Ethereum between accounts. Leveraging ReactJS for a sleek interface, and fortified by Solidity and smart contracts, it ensures secure and transparent transactions, embodying the synergy of frontend excellence and blockchain innovation."
    },
    {
        id:6, 
        name:"News Application", 
        src:<img src={newsApplication} alt='News Application' loading='lazy' width={400} />, 
        live:'https://jp-news-six.vercel.app/', 
        git:'https://github.com/jatin-pandey01/JPNews',
        info:"JPNews, a cutting-edge news application, is crafted with Next.js and Tailwind CSS, providing a seamless reading experience across categories like Nation to Technology. Leveraging Firebase for server-side functionality ensures secure authentication and enables users to store their favorite articles, enhancing personalization."
    },
    {
        id:2, 
        name:"Rick And Morty Character", 
        src:<img src={rickAndMorty} alt='Rick And Morty Character' loading='lazy' width={400} />, 
        live:'https://jatin-pandey01.github.io/Rick-and-Morty-character/', 
        git:'https://github.com/jatin-pandey01/Rick-and-Morty-character',
        info:"The Rick and Morty Character app, powered by React and the Rick and Morty API, offers a user-friendly platform to explore and search through the diverse array of characters from the popular animated series. Users can easily discover detailed information about their favorite characters from the Rick and Morty universe."
    },
    {
        id:3, 
        name:"Ecomzy", 
        src:<img src={ecomzy} alt='Ecomzy' loading='lazy' />, 
        live:'https://ecomzy-three.vercel.app/', 
        git:'https://github.com/jatin-pandey01/Ecomzy',
        info:"Ecomzy, a cutting-edge ecommerce platform, is expertly crafted using React for a responsive and dynamic user interface. Enhanced with animated CSS, the user experience is visually captivating and engaging. Local state management is seamlessly handled by Redux, ensuring efficient and centralized control over the application."
    },
    {
        id:4, 
        name:"JPMedia", 
        src:<img src={jpMedia} alt='JPMedia' loading='lazy' width={400} />, 
        live:'https://jp-media-omega.vercel.app/', 
        git:'https://github.com/jatin-pandey01/JPMedia',
        info:"JPMedia, a dynamic video platform akin to YouTube, is meticulously constructed with React, delivering a seamless and interactive user interface. Animated CSS adds a visually appealing touch to the user experience. Rapid API integration empowers JPMedia with swift access to content, ensuring users enjoy a seamless streaming experience on this innovative video-sharing platform."
    },
    {
        id:5, 
        name:"Courses", 
        src:<img src={courses} alt='Courses' loading='lazy' width={400} />, 
        live:'https://jatin-pandey01.github.io/Courses/', 
        git:'https://github.com/jatin-pandey01/Courses',
        info:"Courses, a versatile learning platform built with ReactJS, features an engaging interface with animated CSS, allowing users to explore diverse categories like Development, Business, Design, and Lifestyle. With React-icons and React-toast, users can seamlessly express their preferences, liking or disliking courses. All information related to course fetched from API."
    }
]

export const skills = [
    {
        id:1,
        src: <SiCplusplusbuilder/>,
        name:"C++",
    },
    {
        id:2,
        src: <FaJava/>,
        name:"Java"
    },
    {
        id:3,
        src: <SiPython/>,
        name:"Python"
    },
    {
        id:4,
        src:<SiSolidity/>,
        name:"Solidity"
    },
    {
        id:5,
        src:<SiGit/>,
        name:"Git"
    },
    {
        id:6,
        src: <SiHtml5/>,
        name:"HTML 5"
    },
    {
        id:7,
        src: <SiCss3/>,
        name:"CSS 3"
    },
    {
        id:8,
        src: <SiJavascript/>,
        name:"Javascript"
    },
    {
        id:9,
        src: <SiReact/>,
        name:"Reactjs"
    },
    {
        id:10,
        src:<TbBrandNextjs/>,
        name:"Nextjs"
    },
    {
        id:11,
        src: <SiExpress/>,
        name:"Express.js"
    },
    {
        id:12,
        src: <SiNodedotjs/>,
        name:"Node.js"
    },
    {
        id:13,
        src:<SiFirebase/>,
        name:"Firebase",
    },
    {
        id:14,
        src: <SiMysql/>,
        name:"MySQL"
    },
    {
        id:15,
        src:<SiMongodb/>,
        name:"MongoDB"
    }
]

export const tools = [
    {
        id:1,
        src:<TbBrandVscode/>
    },
    {
        id:2,
        src:<SiFigma/>
    },
    {
        id:3,
        src:<SiGithub/>
    },
    {
        id:4,
        src:<SiPostman/>
    },
    {
        id:5,
        src:<SiVercel/>
    }
]
