import React, { useEffect } from 'react';
import { Data } from '../constant/Data';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Example from '../components/Card';
import TiltCard from '../components/Card';

const Project = ({mode,setMode}) => {
  useEffect(()=>{
    document.title = "PROJECTS | Jatin Portfolio"
  },[]);
  return (
    <div>
      <Navbar mode={mode} setMode={setMode} isBlack={false} />
      <div className='pt-8 md:pt-28 '>
      <p className='text-3xl font-bold text-center'> My Recent <span className='font-black text-purple-700'>Works</span> </p>
      <div className="flex py-5 justify-center text-white flex-wrap mb-10">
        {
          Data.map((d)=>{
            // return <TiltCard name = {d.name} src = {d.src} info = {d.info} live = {d.live} git = {d.git} />
            return <div className='blue-glassmorphism max-w-sm p-5 relative mx-8 my-8 flex flex-col items-center hover:scale-[1.03] duration-500' key={d.id}>
              <p className='pb-2'> {d.src} </p>
              <p className="flex justify-center pb-2 text-xl font-bold"> {d.name} </p>
              <p className='text-justify pb-2'> {d.info} </p>
              <div className='flex justify-between mx-auto w-[200px] text-lg font-medium'> <a href={d.live} target="_blank" className='bg-blue-500 px-4 rounded py-1 hover:bg-green-500'> Live </a> <a href={d.git} target="_blank" className='bg-blue-500 px-4 rounded py-1 hover:bg-green-500'> Code </a> </div>
            </div>
          })
        }
      </div>
      <Footer />
      </div>
    </div>
  )
}

export default Project;