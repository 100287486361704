import React, { useEffect } from 'react';
import image from './../assets/Image.png';
import {FaLinkedinIn} from 'react-icons/fa';
import {AiFillGithub, AiOutlineTwitter, AiFillInstagram, AiOutlineSend} from 'react-icons/ai';
import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Form from '../components/Form';
import Navbar from '../components/Navbar';

const Home = ({mode,setMode}) => {
    useEffect(()=>{
        document.title = "HOME | Jatin Portfolio"
    },[]);
  return (
    <div>
        <Navbar mode={mode} setMode={setMode} isBlack={false} />
        <div className={`pt-20 flex flex-col gap-28 sm:gap-56 md:pt-56 sm:pb-0 ${mode ? 'text-black light-gradient' :'text-white black-gradient'}`}>
        <div>
            <div className='hidden md:flex justify-center gap-14 items-center'>
                <div className='flex flex-col px-10 gap-3'>
                    <a href='https://www.linkedin.com/in/jatinpandey3250/' target='_blank' > <FaLinkedinIn className='text-2xl hover:scale-125' /> </a>
                    <a href='https://github.com/jatin-pandey01' target='_blank'> <AiFillGithub className='text-2xl hover:scale-125 hover:shadow-2xl' /> </a>
                    <a href='https://twitter.com/Jatin__Pandey' target='_blank'> <AiOutlineTwitter className='text-2xl hover:scale-125 hover:shadow-2xl' /> </a>
                    <a href='https://www.instagram.com/cout.jatinpandey' target='_blank'> <AiFillInstagram className='text-2xl hover:scale-125 hover:shadow-2xl' /> </a>
                </div>
                <div className='flex w-72 flex-col text-lg gap-5'>
                    <h1 className='text-start text-5xl md:text-5xl font-bold'>Hi, I'm Jatin</h1>
                    <p className='flex gap-1 text-opacity-80 '>I am <span className={`${mode ?'text-purple-900' : 'text-blue-400'} text-opacity-100 font-bold`}>
                        <Typewriter
                            options={{
                            strings: ['an Android Developer','a Web Developer','a Programmer','a Coder'],
                            autoStart: true,
                            loop: true,
                            }}
                        />
                    </span>
                    </p>
                    <p className='text-start text-opacity-80 text-lg'> Building Software to solve real world problems </p>
                    <Link to='/resume' className='flex items-center text-xl font-bold gap-2 pt-3 text-white bg-[rgb(110,84,224)] w-fit px-3 rounded-md py-2 hover:bg-purple-800' >Resume <AiOutlineSend/>  </Link>
                </div>
                <div>
                    <img src={image} className='w-[200px] md:z-50 image-radius bg-[rgb(110,84,224)]' />
                </div>
            </div>
            <div className='flex flex-col gap-5 md:hidden'>
                <div className='flex px-6 gap-5 xxs:gap-10 items-center'>
                    <div className='flex xs:pr-14 flex-col gap-3'>
                        <a href='https://www.linkedin.com/in/jatinpandey3250/' target='_blank' > <FaLinkedinIn className='text-xl hover:scale-125' /> </a>
                        <a href='https://github.com/jatin-pandey01' target='_blank'> <AiFillGithub className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
                        <a href='https://twitter.com/Jatin__Pandey' target='_blank'> <AiOutlineTwitter className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
                        <a href='https://www.instagram.com/cout.jatinpandey' target='_blank'> <AiFillInstagram className='text-xl hover:scale-125 hover:shadow-2xl' /> </a>
                    </div>
                    <div>
                        <img src={image} className='w-[200px] z-50 image-radius bg-[rgb(110,84,224)]' />
                    </div>
                </div>
                <div className='flex max-w-xs flex-col px-5 text-lg gap-3'>
                    <h1 className='text-start text-2xl xxs:text-5xl font-bold'>Hi, I'm Jatin</h1>
                    <p className='flex gap-1 text-opacity-70 '>I am <span className={`${mode ?'text-purple-900' : ' text-white font-bold'} opacity-100 font-semibold`}>
                        <Typewriter
                            options={{
                            strings: ['an Android Developer','a Web Developer','a Programmer','a Coder'],
                            autoStart: true,
                            loop: true,
                            }}
                        />
                    </span>
                    </p>
                    <p className='text-start opacity-60 text-lg'> Building Software to solve real world problems </p>
                    <Link to='/resume' className='flex items-center text-xl font-bold gap-2 pt-3 text-white bg-[rgb(110,84,224)] w-fit px-3 rounded-md py-2 hover:bg-purple-800 font-bold'> Resume <AiOutlineSend/>  </Link>
                </div>
            </div>
        </div>
        <div className='flex flex-col sm:mb-[-100px] gap-10'>
            <div className='text-2xl xs:text-3xl sm:text-5xl uppercase text-center'> Let me Introduce myself </div>
            <div className='flex justify-center items-center gap-5 mx-4 sm:gap-14 flex-wrap'>
                <img src={image} className='img-gradient w-52 md:z-50 xs:w-72 rounded-xl' loading='lazy' />
                <p className='max-w-xs text-xl'>Hey there, <span className='text-xl xxs:text-2xl'>👋</span> I'm Jatin a 20 year old CSE undergrad who is passionate about Software development, Problem Solving.
                        Currently, I'm pursuing my career as a Full Stack Web Developer with 2 months of professional experience. </p>
            </div>
        </div>
        <Form />
        </div>
        <Footer mode={mode}/>
    </div>
  ) 
}

export default Home;